import styled from "styled-components";

export const Wrapper = styled.div`
`;

export const Container = styled.div`
  width: 52.3%;
  margin: 0 auto;
  max-width: 900px;
  padding: 80px 0;

  h3 {
    color: #374151;
    text-align: center;
    font-family: Bricolage Grotesque;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 56px */
    letter-spacing: -0.24px;
    margin-bottom: 40px;
    
    @media (max-width: 900px) {
      text-align: center;
      font-size: 32px;
      line-height: 45px; /* 111.765% */
    }
  }

  @media (max-width: 900px) {
    width: 92%;
    padding: 40px 0;
  }
`;

