import styled from "styled-components";

export const Wrapper = styled.div`
    
    @media (max-width: 900px) {
      display: none;
    }
`;

export const MobileWrapper = styled.div`
    @media (min-width: 900px) {
      display: none;
    }
`;