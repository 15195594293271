import styled from "styled-components";

export const FooterWrapper = styled.footer`
  @media (max-width: 900px) {
    display: none;
  }
`;
export const FooterContainer = styled.div`
  width: 90%;
  max-width: 1280px;
  margin: 0 auto;
  margin-top: 100px;
  font-family: 'satoshi', sans-serif;
  padding-bottom: 40px;
`;

export const FooterGridContainer = styled.div`
  display: grid;
  grid-template-columns: 27.9% 17.9% 15.8% 17.6%;
  justify-content: space-between;
`;

export const LinksBox = styled.div`
  text-align: right;
  margin-bottom: 25px;


  div:first-child {
    color: #282356;
    font-size: 14px;

    span {
      color: #30bced;
    }

    a {
      color: #30bced;
      font-size: 14px;
    }
  }
`;

export const IconsBox = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  
  a {
    background: #f5f5f5;
    width: 24px;
    height: 24px;
    border-radius: 100px;
    display: grid;
    place-items: center;
  }
`;

export const AppContainer = styled.div`
  display: flex;
  gap: 16px;
  
  a {
    display: flex;
    align-items: center;
    width: fit-content;
    background: #FFF;
    padding: 5px 10px;
    border-radius: 999px;
    gap: 10px;
    
    img {
      width: 27px;
      height: 27px;
    }
    
    span {
      font-weight: 700;
      font-size: 11px;
      color: #435179;
    }
  }
  
  @media (max-width: 1200px) {
    gap: 10px;
    width: 90%;
    
  }
`;


export const FooterColumn = styled.div`
  
  h5 {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #374151;
    margin-bottom: 24px;
  }
  
  a {
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    color: #6B7280;
    display: block;
    margin-bottom: 21px;
    
    
    &:is(.download) {
      margin-bottom: 0 !important;
      
      div {
        transition: all ease-out .5s;
        
        &:hover {
          background: #282356;

          div {
            p {
              color: white !important;
            }
          }
        }
        
        :first-child {
          @media screen and (min-width: 901px) and (max-width: 1348px) {
            padding: 10px 11px;
          }
          
          .store {
            @media screen and (min-width: 901px) and (max-width: 1348px) {
              font-size: 14px;
            }
          }
        }
      }
      
      
      :hover {
        color: #6B7280;
      }
      
    }
    
    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      color: #30bced;
    }
  }
`;