import styled from "styled-components";
import {motion} from "framer-motion";

export const Wrapper = styled.div`
    position: relative;
    font-family: "Bricolage Grotesque";
`;

export const Container = styled(motion.div)`
    width: 53%;
    max-width: 800px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    
    @media (max-width: 900px) {
        width: 95%;
    }
`;

export const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    
    h2 {
        color: #FFF;
        font-size: 64px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        
        @media (max-width: 900px) {
            font-size: 40px;
            text-align: center;
        }
    }
    p {
        color: #FFF;
        text-align: center;
        font-family: Satoshi;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-top: 24px;
        
        @media (max-width: 900px) {
            font-size: 16px;
        }
    }
    a {
        margin-top: 80px;
        color: #282356;
        text-align: center;
        font-feature-settings: 'cv10' on, 'ss01' on, 'liga' off, 'calt' off;
        font-family: Satoshi;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 160%; /* 32px */
        display: flex;
        padding: 12px 40px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 100px;
        background: #7FD0FF;
        transition: all ease .3s;

        &:hover {
            background: #282356;
            color: #7FD0FF;
        }
        
        @media (max-width: 900px) {
            margin-top: 64px;
            font-size: 16px;
        }
    }
`;