import styled from "styled-components";
import {motion} from "framer-motion";

export const Item = styled.div`
  padding: 24px 0;
  font-family: "satoshi",serif;
`;

export const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  @media (max-width: 900px) {
    align-items: flex-start;
    gap: 24px;
  }
`;

export const Answer = styled(motion.div)`
  //margin-top: 2.6rem;
  margin-top: 16px;
`;

export const AnswerContent = styled(motion.div)`
  display: flex;
  flex-direction: column;
  color: #6B7280;
  font-size: 16px;
  font-weight: 400;
  opacity: .9;
    
    a {
        color: #7FD0FF;
    }

`;

export const Question = styled(motion.div)`
  cursor: pointer;
  color: #374151;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 28.8px */
  letter-spacing: -0.5px;
  text-transform: capitalize;
    width: 80%;

  @media (max-width: 900px) {
    font-size: 18px;
    line-height: 24px;
      width: 100%;
      
  }
`;

export const Bottom = styled(motion.div)`
  overflow: hidden;
`;