import styled from "styled-components";

export const Wrapper = styled.div`
    position: relative;
    height: fit-content;
    overflow: hidden;

    .bg {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        //max-width: 100%;
        opacity: 30%;
        mix-blend-mode: overlay;

        @media (max-width: 900px) {
            opacity: 50%;
        }
    }
`;
export const Container = styled.div`
  padding: 80px 0;
  max-width: 1280px;
    width: 85%;
  margin: 0 auto;
    display: flex;
    align-items: center;
    
  
  @media screen and (max-width: 1200px) {
    gap: 30px;
      padding-bottom: 0;
    padding-top: 65px;
    align-items: center;
      width: 95%;
      flex-direction: column;
  }
`;
export const Left = styled.div`
    width: 65%;

    h1 {
        color: #374151;
        font-family: Bricolage Grotesque,serif;
        font-size: 64px;
        font-style: normal;
        font-weight: 700;
        line-height: 110%;
        margin-bottom: 16px;

        span {
            color: #7FD0FF;
        }

        @media (max-width: 900px) {
            font-size: 32px;
            line-height: 38.6px;
        }
    }

    .desc {
        color: #6B7280;
        font-family: "satoshi";
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 33px; /* 44.8px */

        @media (max-width: 900px) {
            width: 100%;
            margin: 0 auto;
            text-align: center;
            font-size: 20px;
        }
    }

    @media (max-width: 900px) {
        width: 100%;
        margin: 0 auto;
    }
`;
export const Right = styled.div`
    width: 35%;

    @media (max-width: 900px) {
        width: 100%;
    }
`;