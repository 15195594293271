import styled from "styled-components";

export const Wrapper = styled.div`
  font-family: "satoshi";
  padding: 20px 0;

  .signup {
    :hover {
      background: #282356;
      color: #7FD0FF;
      transition: all ease-out .3s;

      span {
        svg path {
          stroke:  #7FD0FF !important;
        }
      }
    }
  }

  @media screen and (max-width: 900px) {
    display: none;
  }
`;
export const Container = styled.div`
  width: 84.6%;
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  
`;

export const Left = styled.div`
    
`;

export const Right = styled.div`
    display: flex;
  align-items: center;
  gap: 40px;
  
  .signup {
    :hover {
      background: #282356;
      color: #7FD0FF;
      transition: all ease-out .3s;
      
      span {
        svg path {
          stroke:  #7FD0FF !important;
        }
      }
    }
  }
  
`;

export const Column = styled.div`
    h5 {
      color: #374151;
      font-family: "satoshi",serif;
      font-size: 24px;
      line-height: 28px;
      font-weight: 500;

      @media screen and (min-width: 901px) and (max-width: 1290px) {
        font-size: 21px;
        margin-bottom: 18px;
      }
    }
  
  div {
    a {
      color: #6B7280;
      font-family: "satoshi",serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%; /* 24px */
      transition: all ease .2s;
      
      @media screen and (min-width: 901px) and (max-width: 1290px) {
        font-size: 13px;
      }
      
      &:hover {
        color: #30bced;
        
      }
    }

    @media screen and (min-width: 901px) and (max-width: 1290px) {
      gap: 17px;
    }
  }
`;