import styled from "styled-components";

export const FooterWrapper = styled.footer`
  padding-bottom: 30px;
  @media (min-width: 900px) {
    display: none;
  }
`;

export const FooterContainer = styled.div`
  width: 92%;
  margin: 0 auto;
  font-family: 'satoshi', sans-serif;
  display: flex;
  flex-wrap: wrap;
  gap: 48px;
  padding-bottom: 24px;
`;

export const LinksBox = styled.div`
  text-align: right;
  margin-bottom: 25px;


  div:first-child {
    color: #282356;
    font-size: 14px;

    span {
      color: #30bced;
    }

    a {
      color: #30bced;
      font-size: 14px;
    }
  }
`;

export const IconsBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;

  a {
    background: #f5f5f5;
    width: 24px;
    height: 24px;
    border-radius: 100px;
    display: grid;
    place-items: center;
  }
`;

export const FooterColumn = styled.div`

  h5 {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #374151;
    margin-bottom: 24px;
  }

  a {
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    color: #6B7280;
    display: block;
    margin-bottom: 21px;

    &:is(.download) {
      margin-bottom: 0 !important;
      
      .btn-container {
        @media (max-width: 375px) {
          padding: 11px 14px;
          gap: 6px;

          p {
            :last-child {
              font-size: 14px;
            }
          }
        }
        
        
      }

      :hover {
        color: #6B7280;
      }

    }

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      color: #30bced;
    }
  }
`;