import React from 'react';
import DesktopPrefer from "./DesktopPrefer";
import MobilePrefer from "./MobilePrefer";

const Prefer = () => {
    return (
        <div style={{fontFamily: "Bricolage Grotesque"}}>
            <DesktopPrefer />
            <MobilePrefer />
        </div>
    );
};

export default Prefer;