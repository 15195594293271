import styled from "styled-components";

export const Wrapper = styled.div`

  .mobile-btn {
    @media (max-width: 370px) {
      padding: 12px 24px;
    }
  }
  
  @media screen and (min-width: 900px) {
    display: none;
  }
`;

export const IconsBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;

  a {
    width: 24px;
    height: 24px;
    border-radius: 100px;
    display: grid;
    place-items: center;
  }
`;