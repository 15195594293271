import DesktopNgcNavbar from "./DesktopNavbar";
import MobileNgcNavbar from "./MobileNavbar";

const NewNavbar = ({dark}) => {
    return (
        <div className={"z-50 relative"}>
            <DesktopNgcNavbar dark={dark} />
            <MobileNgcNavbar dark={dark} />
        </div>
    );
};

export default NewNavbar;