import {Wrapper, Container, Left, Right} from "./styles";
import {Navigation} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react";
import LeftArrow from "../../assets/images/left.svg";
import RightArrow from "../../assets/images/right.svg";
import BG from "../../assets/images/bg.svg";
import Avatar from "../../assets/images/avatar.webp";

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';

const TestimonialSlider = () => {
    return (
        <Swiper
            spaceBetween={30}
            navigation={{
                prevEl: ".prev-btn",
                nextEl: ".next-btn"
            }}
            modules={[Navigation]}
        >
            <SwiperSlide>
                <Right>
                    <p>I recently sold a gift card on NOSH, and I was blown away by how easy the process was. The
                        platform is intuitive and user-friendly, and I received my payment almost instantly. I will
                        definitely be using NOSH for all my gift card needs going forward.</p>
                    <div>
                        <span>Emenike John</span>
                        <span>Instagram</span>
                    </div>
                </Right>
            </SwiperSlide>
            <SwiperSlide>
                <Right>
                    <p>NOSH has been a game-changer for me! I love the convenience of being able to purchase airtime
                        and pay for my bills all in one place. The platform is easy to use, and their customer
                        support team is always available to help. Highly recommend!</p>
                    <div>
                        <span>Joshua Ramotor</span>
                        <span>Playstore</span>
                    </div>
                </Right>
            </SwiperSlide>
            <SwiperSlide>
                <Right>
                    <p>I recently signed up for {"NOSH's"} virtual bank account, and it has been a game-changer for
                        my business. The platform is secure, and the transactions are fast and reliable. I love the
                        convenience of being able to manage my finances from one place, and I highly recommend NOSH
                        to other business owners.</p>
                    <div>
                        <span>Evaristus Charles</span>
                        <span>Appstore</span>
                    </div>
                </Right>
            </SwiperSlide>
            <SwiperSlide>
                <Right>
                    <p>{"NOSH's"} in-app chat support is amazing! I recently had an issue with a transaction, and
                        their support team was able to resolve it within minutes. The response time was impressive,
                        and the support representative was friendly and knowledgeable. I am very impressed
                        with {"NOSH's"} customer service.</p>
                    <div>
                        <span>Adewunmi Praise</span>
                        <span>Playstore</span>
                    </div>
                </Right>
            </SwiperSlide>
            <SwiperSlide>
                <Right>
                    <p>{"NOSH's"} platform is so versatile! I use it to buy gift cards, top up my airtime, and even
                        pay for my utility bills. {"It's"} so convenient to have all these services in one place,
                        and the platform is intuitive and easy to use. I highly recommend NOSH to anyone looking for
                        a reliable and versatile payment solution.</p>
                    <div>
                        <span>Oladele Mathew</span>
                        <span>Twitter</span>
                    </div>
                </Right>
            </SwiperSlide>
            <SwiperSlide>
                <Right>
                    <p>{"I've"} been using NOSH to pay my cable TV subscription, and it has been a breeze! I no
                        longer have to deal with long queues or delayed payments. The platform is fast and reliable,
                        and I love how I can manage all my bills in one place. NOSH has definitely made my life
                        easier!</p>
                    <div>
                        <span>Chioma Chuks</span>
                        <span>Facebook</span>
                    </div>
                </Right>
            </SwiperSlide>
        </Swiper>
    )
}

const Testimonial = () => {
    return (
        <Wrapper>
            <img className={"bg"} src={BG} alt=""/>
            <Container>
                <h2>What our users are saying</h2>

                <div className={"flex flex-col lg:flex-row items-center gap-10 lg:gap-[80px]"}>
                    <Left>
                        <img src={Avatar} alt=""/>
                    </Left>

                    <div className={"w-full lg:max-w-[600px] flex-1"}>
                        <TestimonialSlider />
                    </div>
                </div>


                <div className={"mt-10 flex items-center lg:justify-end"}>
                    <div className={"flex items-center gap-10"}>
                        <img className={"cursor-pointer w-[72px] prev-btn"} src={LeftArrow} alt=""/>
                        <img className={"cursor-pointer w-[72px] next-btn"} src={RightArrow} alt=""/>
                    </div>
                </div>
            </Container>
        </Wrapper>
    );
};

export default Testimonial;