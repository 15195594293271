import styled from "styled-components";
const ArrowRight = <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.62012 3.95337L13.6668 8.00004L9.62012 12.0467" stroke="#7FD0FF" stroke-width="1.5"
          stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M2.33325 8H13.5533" stroke="#7FD0FF" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"
          stroke-linejoin="round"/>
</svg>

const DesktopPrefer = () => {
    return (
        <Wrapper>
            <Container>
                <Left>
                    <p>What do you prefer?</p>
                    <JoinNosh href={"https://web.usenosh.com/signup"} rel={"noreferrer"} target={"_blank"}>
                        Join Nosh Today {ArrowRight}
                    </JoinNosh>
                </Left>

                <Right>
                    <div className="top hidden lg:flex justify-between items-center gap-6">
                        <p className={"w-[33%] text-[#6B7280]"}>Feature</p>
                        <p className={"w-[31%] text-[#374151] text-center"}>Nosh</p>
                        <p className={"w-[31%] text-[#374151] text-center"}>‘DEM OTHER PLATFORMS’</p>
                    </div>

                    <div className="bottom">
                        <div className={"bg-[#F5F5F5] rounded-xl"}>
                            <p className={"w-[33%] text-[22px] text-[#374151] text-left"}>Instant
                                Transaction <br/> Speed</p>
                            <p className={"w-[31%] text-[20px] text-[#6B7280] text-center"}>A few minutes</p>
                            <p className={"w-[31%] text-[20px] text-[#6B7280] text-center"}>600 Years 😠</p>
                        </div>
                        <div>
                            <p className={"w-[33%] text-[22px] text-[#374151] text-left"}>Withdrawal Charges</p>
                            <p className={"w-[31%] text-[20px] text-[#6B7280] text-center"}>Free</p>
                            <p className={"w-[31%] text-[20px] text-[#6B7280] text-center"}>Ridiculous</p>
                        </div>
                        <div className={"bg-[#F5F5F5] rounded-xl"}>
                            <p className={"w-[33%] text-[22px] text-[#374151] text-left"}>Realtime <br/> Notifications</p>
                            <p className={"w-[31%] text-[20px] text-[#6B7280] text-center"}>Always</p>
                            <p className={"w-[31%] text-[20px] text-[#6B7280] text-center"}>Not Important</p>
                        </div>
                        <div>
                            <p className={"w-[33%] text-[22px] text-[#374151] text-left"}>Customer Support</p>
                            <p className={"w-[31%] text-[20px] text-[#6B7280] text-center"}>24/7</p>
                            <p className={"w-[31%] text-[20px] text-[#6B7280] text-center"}>Only in the morning 😴</p>
                        </div>
                        <div className={"bg-[#F5F5F5] rounded-xl"}>
                            <p className={"w-[33%] text-[22px] text-[#6B7280] text-left"}>Rates</p>
                            <p className={"w-[31%] text-[20px] text-[#6B7280] text-center"}>90%</p>
                            <p className={"w-[31%] text-[20px] text-[#6B7280] text-center"}>60%</p>
                        </div>
                        <div>
                            <p className={"w-[33%] text-[22px] text-[#374151] text-left"}>Security</p>
                            <p className={"w-[31%] text-[20px] text-[#6B7280] text-center"}>100%</p>
                            <p className={"w-[31%] text-[20px] text-[#6B7280] text-center"}>🤡</p>
                        </div>
                        <div className={"bg-[#F5F5F5] rounded-xl"}>
                            <p className={"w-[33%] text-[22px] text-[#6B7280] text-left"}>Uptime</p>
                            <p className={"w-[31%] text-[20px] text-[#6B7280] text-center"}>24/7</p>
                            <p className={"w-[31%] text-[20px] text-[#6B7280] text-center"}>Depends on mood</p>
                        </div>
                    </div>
                </Right>
            </Container>
        </Wrapper>
    );
};

export default DesktopPrefer;

const JoinNosh = styled.a`
    font-family: 'satoshi';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    display: flex;
    align-items: center;
    gap: 8px;
    transition: all ease .6s;
    border-radius: 100px;
    background: #F5F5F5;
    padding: 12px 40px;
    width: fit-content;
    color: #7FD0FF;
    margin-top: 24px;
    
`;
const Wrapper = styled.div`
    @media (max-width: 900px) {
        display: none;
    }
`;
const Container = styled.div`
    width: 85%;
    max-width: 1280px;
    margin: 0 auto;
    display: flex;
    gap: 80px;
    padding: 80px 0;
`;
const Left = styled.div`
    width: 30.2%;
    max-width: 390px;
    p {
        color: #374151;
        font-size: 40px;
        font-style: normal;
        font-weight: 600;
        line-height: 110%; /* 44px */
    }
`;
const Right = styled.div`
    width: 64%;
    flex: 1;
    
    .top {
        p {
            padding: 24px 16px;
            font-family: "Bricolage Grotesque";
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 32px; /* 133.333% */
        }
    }
    
    .bottom {
        font-family: "satoshi";
        div {
            display: flex;
            align-items: center;
            gap: 24px;
            
            p {
                padding: 16px; 
                font-weight: 400;
            }
        }
    }
`;