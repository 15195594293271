import {Wrapper, MobileWrapper} from "./styles";
import {useEffect, useState} from "react";
import AppStore from "../../assets/images/new-apple.svg";
import PlayStore from "../../assets/images/new-play.svg";
import Web from "../../assets/images/webs.svg";

const Links = ({links}) => {
    const [isAndroid, setIsAndroid] = useState(false);
    const [isIOS, setIsIOS] = useState(false);

    useEffect(() => {
        // Check if the user agent string contains "Android"
        const androidCheck = /Android/i.test(navigator.userAgent);

        // Check if the user agent string contains "iPhone" or "iPad" (for iOS)
        const iosCheck = /iPhone|iPad/i.test(navigator.userAgent);

        setIsAndroid(androidCheck);
        setIsIOS(iosCheck);
    }, []);

    return (
        <>
            <Wrapper>
                <div style={{fontFamily: "satoshi"}} className={"flex gap-2"}>
                    <a className={"w-fit"} rel="noreferrer" target="_blank" href="https://apple.co/3UVMaj4">
                        <div className={`transition-all ease-in-out duration-200 hover:bg-[#282356] group px-6 py-3 rounded-full flex gap-2 items-center ${links ? "bg-[#f5f5f5]" : "bg-[#FFF]"}`}>
                            <img loading={"lazy"} src={AppStore} className={"w-8 h-8"} alt=""/>
                            <div className={"-space-y-1"}>
                                <p className={"text-[10px] group-hover:text-white text-[#6B7280] block"}>Download on the </p>
                                <p className={"text-[14px] group-hover:text-white font-medium text-[#374151]"}>App Store</p>
                            </div>
                        </div>
                    </a>
                    <a className={"w-fit"} rel="noreferrer" target="_blank" href="https://bit.ly/3SHHxqM">
                        <div className={`transition-all ease-in-out duration-200 hover:bg-[#282356] group px-6 py-3 rounded-full flex gap-2 items-center ${links ? "bg-[#f5f5f5]" : "bg-[#FFF]"}`}>
                            <img loading={"lazy"} src={PlayStore} className={"w-8 h-8"} alt=""/>
                            <div className={"-space-y-1"}>
                                <p className={"text-[10px] group-hover:text-white text-[#6B7280]"}>Get it on</p>
                                <p className={"text-[14px] group-hover:text-white font-medium text-[#374151]"}>Google
                                    Play</p>
                            </div>
                        </div>
                    </a>
                    <a className={"w-fit"} rel="noreferrer" target="_blank" href="https://bit.ly/3RsTOy6">
                        <div className={`transition-all ease-in-out duration-200 hover:bg-[#282356] group px-6 py-3 rounded-full flex gap-2 items-center ${links ? "bg-[#f5f5f5]" : "bg-[#FFF]"}`}>
                            <img loading={"lazy"} src={Web} className={"w-8 h-8"} alt=""/>
                            <div className={"-space-y-1"}>
                                <p className={"text-[10px] group-hover:text-white text-[#6B7280]"}>Use it on</p>
                                <p className={"text-[14px] group-hover:text-white font-medium text-[#374151]"}>Web</p>
                            </div>
                        </div>
                    </a>
                </div>
            </Wrapper>

            <MobileWrapper>
                <div style={{fontFamily: "satoshi"}} className={"flex justify-center gap-2"}>
                {isIOS && <a className={"w-fit"} rel="noreferrer" target="_blank" href="https://apple.co/3UVMaj4">
                        <div className={`${links ? "bg-[#f5f5f5]" : "bg-white"} px-6 py-3 rounded-full flex gap-2 items-center`}>
                            <img loading={"lazy"} src={AppStore} className={"w-8 h-8"} alt=""/>
                            <div className={"-space-y-1"}>
                                <p className={"text-[10px] text-[#6B7280] block"}>Download on the </p>
                                <p className={"text-[14px] font-medium text-[#374151]"}>App Store</p>
                            </div>
                        </div>
                    </a>}
                    {isAndroid && <a className={"w-fit"} rel="noreferrer" target="_blank" href="https://bit.ly/3SHHxqM">
                        <div className={`${links ? "bg-[#f5f5f5]" : "bg-white"} px-6 py-3 rounded-full flex gap-2 items-center`}>
                            <img loading={"lazy"} src={PlayStore} className={"w-8 h-8"} alt=""/>
                            <div className={"-space-y-1"}>
                                <p className={"text-[10px] text-[#6B7280]"}>Get it on</p>
                                <p className={"text-[14px] font-medium text-[#374151]"}>Google Play</p>
                            </div>
                        </div>
                    </a>}
                    <a className={"w-fit"} rel="noreferrer" target="_blank" href="https://bit.ly/3RsTOy6">
                        <div className={`${links ? "bg-[#f5f5f5]" : "bg-white"} px-6 py-3 rounded-full flex gap-2 items-center`}>
                            <img loading={"lazy"} src={Web} className={"w-8 h-8"} alt=""/>
                            <div className={"-space-y-1"}>
                                <p className={"text-[10px] text-[#6B7280]"}>Use it on</p>
                                <p className={"text-[14px] font-medium text-[#374151]"}>Web</p>
                            </div>
                        </div>
                    </a>
                </div>
            </MobileWrapper>
        </>
    );
};

export default Links;