import {Wrapper, Container} from "./styles";
import FaqItem from "./FaqItem";

const FAQ = ({data, giftCard, type, title}) => {
    return (
        <Wrapper>
            <Container>
                {title && <h3>{title}</h3>}
                {!title && <h3>FAQs About {type} {giftCard} Gift Card</h3>}
                {data.map((item, index) => <FaqItem index={index} key={index} item={item}/>)}
            </Container>
        </Wrapper>
    );
};

export default FAQ;