import {motion} from "framer-motion";
import styled from "styled-components";
const ArrowRight = <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.62012 3.95337L13.6668 8.00004L9.62012 12.0467" stroke="#7FD0FF" stroke-width="1.5"
          stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M2.33325 8H13.5533" stroke="#7FD0FF" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"
          stroke-linejoin="round"/>
</svg>


const MobilePrefer = () => {
    return (
        <section className={"md:hidden bg-[#fff] pb-[50px] pt-[30px]"}>
            <motion.div initial={{opacity: 0}} whileInView={{opacity: 1}} viewport={{once: true}} transition={{delay: .5, duration: 1.3,  ease: [0.6, 0.01, -0.05, 0.9]}} className={"text-white"}>
                <Title>What Do You Prefer?</Title>

                <JoinNosh href={"https://web.usenosh.com/signup"} rel={"noreferrer"} target={"_blank"}>
                    Join Nosh Today {ArrowRight}
                </JoinNosh>

                <div className={"mt-16"}>
                    <div className={"bg-[#F5F5F5] border border-[#F5F5F5] w-[90%] mx-auto p-4 rounded-xl"}>
                        <p style={{fontFamily: 'satoshi'}} className={"text-[#374151] text-[16px] font-[500] mb-[25px]"}>Instant Transaction Speed</p>
                        <div className={"space-y-5"}>
                            <div className={"flex justify-between"}>
                                <div style={{fontFamily: "Bricolage Grotesque"}}
                                     className={"text-[14px] text-[#374151] font-medium"}>NOSH
                                </div>
                                <div style={{fontFamily: 'satoshi'}} className={"text-[15px] text-[#6B7280]"}>A few
                                    minutes
                                </div>
                            </div>

                            <div className={"flex justify-between"}>
                                <div style={{fontFamily: "Bricolage Grotesque"}}
                                     className={"text-[14px] text-[#374151] font-[500]"}>‘DEM OTHER PLATFORMS’
                                </div>
                                <div style={{fontFamily: 'satoshi'}} className={"text-[15px] text-[#6B7280]"}>600
                                    Years😠
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className={"w-[90%] mx-auto p-4"}>
                        <p style={{fontFamily: 'satoshi'}} className={"text-[#374151] text-[16px] font-[500] mb-[25px]"}>Withdrawal Charges</p>

                        <div className={"space-y-5"}>
                            <div className={"flex justify-between"}>
                                <div style={{fontFamily: "Bricolage Grotesque"}}
                                     className={"text-[14px] text-[#374151] font-[500]"}>NOSH
                                </div>
                                <div style={{fontFamily: 'satoshi'}} className={"text-[15px] text-[#6B7280]"}>Free</div>
                            </div>

                            <div className={"flex justify-between"}>
                                <div style={{fontFamily: "Bricolage Grotesque"}}
                                     className={"text-[14px] text-[#374151] font-[500]"}>‘DEM OTHER PLATFORMS’
                                </div>
                                <div style={{fontFamily: 'satoshi'}}
                                     className={"text-[15px] text-[#6B7280]"}>Ridiculous
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className={"bg-[#F5F5F5] border border-[#F5F5F5] w-[90%] mx-auto p-4 rounded-xl"}>
                        <p style={{fontFamily: 'satoshi'}}
                           className={"text-[#374151] text-[16px] font-[500] mb-[25px]"}>Realtime
                            Notifications</p>

                        <div className={"space-y-5"}>
                            <div className={"flex justify-between"}>
                                <div style={{fontFamily: "Bricolage Grotesque"}}
                                     className={"text-[14px] text-[#374151] font-[500]"}>NOSH
                                </div>
                                <div style={{fontFamily: 'satoshi'}} className={"text-[15px] text-[#6B7280]"}>Always
                                </div>
                            </div>

                            <div className={"flex justify-between"}>
                                <div style={{fontFamily: "Bricolage Grotesque"}}
                                     className={"text-[14px] text-[#374151] font-[500]"}>‘DEM OTHER PLATFORMS’
                                </div>
                                <div style={{fontFamily: 'satoshi'}} className={"text-[15px] text-[#6B7280]"}>Not
                                    important
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className={"border-b border-[#30bced1a] w-[90%] mx-auto p-4"}>
                        <p style={{fontFamily: 'satoshi'}}
                           className={"text-[#374151] text-[16px] font-[500] mb-[25px]"}>Customer Support</p>

                        <div className={"space-y-5"}>
                            <div className={"flex justify-between"}>
                                <div style={{fontFamily: "Bricolage Grotesque"}}
                                     className={"text-[14px] text-[#374151] font-[500]"}>NOSH
                                </div>
                                <div style={{fontFamily: 'satoshi'}} className={"text-[15px] text-[#6B7280]"}>24/7</div>
                            </div>

                            <div className={"flex justify-between"}>
                                <div style={{fontFamily: "Bricolage Grotesque"}}
                                     className={"text-[14px] text-[#374151] font-[500]"}>‘DEM OTHER PLATFORMS’
                                </div>
                                <div style={{fontFamily: 'satoshi'}} className={"text-[15px] text-[#6B7280]"}>Only in
                                    the morning 😴
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className={"bg-[#F5F5F5] border border-[#F5F5F5] w-[90%] mx-auto p-4 rounded-xl"}>
                        <p style={{fontFamily: 'satoshi'}}
                           className={"text-[#374151] text-[16px] font-[500] mb-[25px]"}>Rates</p>

                        <div className={"space-y-5"}>
                            <div className={"flex justify-between"}>
                                <div style={{fontFamily: "Bricolage Grotesque"}}
                                     className={"text-[14px] text-[#374151] font-[500]"}>NOSH
                                </div>
                                <div style={{fontFamily: 'satoshi'}} className={"text-[15px] text-[#6B7280]"}>90%</div>
                            </div>

                            <div className={"flex justify-between"}>
                                <div style={{fontFamily: "Bricolage Grotesque"}}
                                     className={"text-[14px] text-[#374151] font-[500]"}>‘DEM OTHER PLATFORMS’
                                </div>
                                <div style={{fontFamily: 'satoshi'}} className={"text-[15px] text-[#6B7280]"}>60%</div>
                            </div>

                        </div>
                    </div>
                    <div className={"w-[90%] mx-auto p-4 rounded-xl"}>
                        <p style={{fontFamily: 'satoshi'}}
                           className={"text-[#374151] text-[16px] font-[500] mb-[25px]"}>Security</p>

                        <div className={"space-y-5"}>
                            <div className={"flex justify-between"}>
                                <div style={{fontFamily: "Bricolage Grotesque"}}
                                     className={"text-[14px] text-[#374151] font-[500]"}>NOSH
                                </div>
                                <div style={{fontFamily: 'satoshi'}} className={"text-[15px] text-[#6B7280]"}>100%</div>
                            </div>

                            <div className={"flex justify-between"}>
                                <div style={{fontFamily: "Bricolage Grotesque"}}
                                     className={"text-[14px] text-[#374151] font-[500]"}>‘DEM OTHER PLATFORMS’
                                </div>
                                <div style={{fontFamily: 'satoshi'}} className={"text-[15px] text-[#6B7280]"}>🤡</div>
                            </div>

                        </div>
                    </div>
                    <div className={"bg-[#F5F5F5] border border-[#F5F5F5] w-[90%] mx-auto p-4 rounded-xl"}>
                        <p style={{fontFamily: 'satoshi'}}
                           className={"text-[#374151] text-[16px] font-[500] mb-[25px]"}>Uptime</p>

                        <div className={"space-y-5"}>
                            <div className={"flex justify-between"}>
                                <div style={{fontFamily: "Bricolage Grotesque"}}
                                     className={"text-[14px] text-[#374151] font-[500]"}>NOSH
                                </div>
                                <div style={{fontFamily: 'satoshi'}} className={"text-[15px] text-[#6B7280]"}>24/7</div>
                            </div>

                            <div className={"flex justify-between"}>
                                <div style={{fontFamily: "Bricolage Grotesque"}}
                                     className={"text-[14px] text-[#374151] font-[500]"}>‘DEM OTHER PLATFORMS’
                                </div>
                                <div style={{fontFamily: 'satoshi'}} className={"text-[15px] text-[#6B7280]"}>Depends on
                                    Mood
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </motion.div>
        </section>
    );
};

export default MobilePrefer;

const JoinNosh = styled.a`
    font-family: 'satoshi';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    display: flex;
    align-items: center;
    gap: 8px;
    transition: all ease .6s;
    border-radius: 100px;
    background: #F5F5F5;
    padding: 12px 40px;
    width: fit-content;
    margin: 0 auto;
    color: #7FD0FF;
    margin-top: 24px;
`;
const Title = styled.h3`
    font-family: 'Bricolage Grotesque', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 36px;
    color: #374151;
    width: 85%;
    margin: 0 auto;
    text-align: center;
`;