import {useState} from 'react';
import {Item, Bottom, Top, Question, Answer, AnswerContent} from "./styles";
import {AnimatePresence} from "framer-motion";

const FaqItem = ({item, index}) => {
   const [isOpen, setIsOpen] = useState(false);
   const toggleFaq = () => setIsOpen((prevState) => !prevState);

    const variants = {
        open: {
            opacity: 1,
            height: "auto"
        },
        closed: {
            opacity: 0,
            height: 0
        }
    }

    return (
        <div>
            <Item>
                <Top onClick={toggleFaq}>
                    <Question index={index}>{item.question}</Question>
                    <div className={"icon-wrapper"}>
                        {isOpen ? <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M19.5 8.25L12 15.75L4.5 8.25" stroke="#7FD0FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg> : <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M19.5 8.25L12 15.75L4.5 8.25" stroke="#7FD0FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>}
                    </div>
                </Top>
                <AnimatePresence>
                    {isOpen && <Bottom key={item.question} exit={"closed"} initial={"closed"} animate={"open"} transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }} variants={variants} >
                        <Answer>
                            <AnswerContent index={index}>
                                {item.answer}
                            </AnswerContent>
                        </Answer>
                    </Bottom>}
                </AnimatePresence>
            </Item>
        </div>
    );
};

export default FaqItem;