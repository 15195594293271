import styled from "styled-components";

export const Wrapper = styled.div`
  font-family: "satoshi",serif;
  
  @media (max-width: 900px) {
    padding-top: 0;
    padding-bottom: 50px;
  }
`;
export const Container = styled.div`
  width: 90%;
  margin: 0 auto;
  max-width: 1280px;
  border-radius: 48px;
  background: #eaf8fd;
  height: 550px;
  padding: 0 59px 0 64px;
  z-index: 1;
  position: relative;
  display: flex;
  align-items: center;

  @media screen and (min-width: 200px) and (max-width: 900px) {
    width: 100%;
    height: fit-content !important;
    //margin-top: 10px;
    border-radius: 0;
    padding: 0;
    padding-top: 48px;
    
  }

  @media screen and (min-width: 900px) and (max-width: 1200px) {
    width: 90%;
    margin: 0 auto;
    height: 100%;
    padding: 20px;
  }
`;
export const ChildContainer = styled.div`
  display: flex;
  align-items: center;
  
  @media (max-width: 900px) {
    flex-direction: column;
    gap: 40px;
    width: 100%;
  }
  
`;
export const LeftBox = styled.div`
  width: 52%;
  
  h3 {
    font-size: 50px;
    text-align: left;
    font-weight: 800;
    color: #374151;
    font-family: 'Bricolage Grotesque', sans-serif;
    
    span {
      color: #7FD0FF;
    }

    @media screen and (min-width: 900px) and (max-width: 1200px) {
      font-family: 'Bricolage Grotesque', sans-serif;
      font-style: normal;
      font-weight: 800;
      font-size: 30px;
      line-height: 41px;
      color: #435179;
      width: 90%;
    }
    
    @media (max-width: 900px) {
      font-family: 'Bricolage Grotesque', sans-serif;
      font-style: normal;
      font-weight: 800;
      font-size: 30px;
      line-height: 41px;
      color: #435179;
      width: 60%;
    }
    
    @media (max-width: 900px) {
      width: 100%;
    }
  }
  
  .description {
    font-size: 25px;
    font-weight: 400;
    color: #282356;
    margin-top: 16px;
    width: 90%;

    @media (max-width: 900px) {
      font-size: 15px;
      //width: 90%;
      font-style: normal;
      line-height: 24px;
      color: #023248;
      width: 80%;

    }
  }
  
  .download-line {
    @media (min-width: 900px) {
      display: none;
    }

    @media (max-width: 900px) {
      margin: 0;
      margin-top: 40px;
      margin-bottom: 40px;
      border-top: 1px solid #D4EFFF;
      width: 100%;
    }
  }
  
  @media (max-width: 900px) {
    width: 90%;
    margin: 0 auto;
    padding: 0;
  }

  @media screen and (min-width: 900px) and (max-width: 1200px) {}
`;
export const AppContainer = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 70px;
  
  a {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 12px 24px;
    border-radius: 100px;
    background: #FFF;
    box-shadow: 0px 8px 16px 0px rgba(17, 17, 17, 0.06);
    transition: all ease-out .5s;

    &:hover {
      background: #282356;

      div {
        p {
          color: white !important;
        }
      }
    }
    //width: 170px;

    @media (max-width: 900px) {
      padding: 12px 16px;
      gap: 8px;
    }
    
    img {
      width: 32px;
    }
    
    div {
      p {
        &:first-child {
          color: #6B7280;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 100%; /* 14px */
          letter-spacing: -0.24px;
          
          @media (max-width: 900px) {
            font-size: 12px;
          }
        }
        &:last-child {
          color: #374151;
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: 120%; /* 24px */
          letter-spacing: -0.24px;

          @media (max-width: 900px) {
            font-size: 16px;
          }
        }
      }
    }
    
    span {

    }
  }
  
  @media (max-width: 900px) {
    gap: 10px;
    width: 100%;
    margin-top: 0;
      justify-content: center;
  }

  @media screen and (min-width: 900px) and (max-width: 1200px) {
    margin-top: 30px !important;
  }
`;
export const RightBox = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;

  .desktop-img {

    @media screen and (min-width: 900px) and (max-width: 1200px) {
      width: 90% !important;
      margin: 0 auto;
      max-width: 100%;
    }
    
    
    @media (max-width: 900px) {
      display: none;
    }
  }
  
  .mobile-img {
    @media (min-width: 900px) {
      display: none;
    }
    max-width: 100% !important;
    width: 100% !important;
    
  }
  
  @media (max-width: 900px) {
    padding-right: 0;
    display: flex;
    justify-content: center;
    width: 100%;
  }
`;