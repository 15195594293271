import styled from "styled-components";

export const Wrapper = styled.div`
    position: relative;
    background: #282356;
    overflow: hidden;
    margin-bottom: 80px;
    
    .bg {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        object-fit: cover;
    }
    
  @media screen and (max-width: 900px) {
      margin-bottom: 0;
  }
`;
export const Container = styled.div`
    max-width: 1280px;
    width: 85%;
    margin: 0 auto;
    padding: 80px 0;
    position: relative;
    
    
    h2 {
        color: #F6F6F6;
        text-align: center;
        font-family: "Bricolage Grotesque";
        font-size: 48px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%; /* 67.2px */
        margin-bottom: 96px;
        
        @media (max-width: 900px) {
            text-align: center;
            font-size: 32px;
            font-style: normal;
            line-height: 140%; /* 44.8px */
            margin-bottom: 64px;
        }
    }

    @media screen and (max-width: 900px) {}
`;
export const Left = styled.div`
    flex: 1;
    max-width: 600px;
    
    img {
        width: 100%;
        max-width: 100%;
        display: block;
    }
`;
export const Right = styled.div`
    p {
        color: #FFF;
        font-family: Satoshi;
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 48px */

        @media (max-width: 900px) {
            font-size: 20px;
            line-height: 30px;
        }
    }
    
    div {
        margin-top: 70px;
        display: flex;
        flex-direction: column;
        gap: 12px;
        
        span {
            color: #FFF;
            font-family: Satoshi;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%; /* 24px */
            
            &:last-child {
                font-weight: 400;
                color: #D1D5DB;
            }
            
            @media (max-width: 900px) {
                font-size: 20px;
            }
            
        }
        
        @media (max-width: 900px) {
            margin-top: 24px;
        }
    }
`;