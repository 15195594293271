import DesktopFooter from "./DesktopFooter/DesktopFooter";
import MobileFooter from "./MobileFooter/MobileFooter";

const NewFooter = () => {
    return (
        <div>
            <DesktopFooter />
            <MobileFooter />
        </div>
    );
};

export default NewFooter;