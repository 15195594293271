import {Wrapper, Container, TextContainer} from "./styles";
import Web from "../../assets/images/web.webp";
import MobileWeb from "../../assets/images/web-mobile.webp";

const YouPlusNosh = ({
                         title="You + NOSH = A Great Life",
                         text="You can sell all types of gift cards in Nigeria conveniently. They include but are not limited to Apple gift card, Steam gift card, Amazon gift card, Razer Gold gift card, iTunes gift card, and Google Play gift card.",
                         buttonText="Trade A Gift Card"}) => {
    return (
        <Wrapper>
            <img className={"desktop hidden md:block w-full"} src={Web} alt=""/>
            <img className={"mobile md:hidden w-full"} src={MobileWeb} alt=""/>

            <Container className={"absolute inset-0 top-1/2 -translate-y-1/2"}>
                <TextContainer>
                    <h2>{title}</h2>
                    <p>{text}</p>
                    <a target={"_blank"} rel={"noreferrer"} href={"https://web.usenosh.com/signup"}>{buttonText}</a>
                </TextContainer>
            </Container>
        </Wrapper>
    );
};

export default YouPlusNosh;