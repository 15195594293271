import {Wrapper, Container, Left, Right} from "./styles";
import NewNavbar from "../NewNavbar/index";
import Links from "../Links/Links";
import Phone  from "../../assets/images/phone.webp";
import MobilePhone  from "../../assets/images/mobile-phone.webp";
import NM from "../../assets/images/nairametrics.png";
import Punch from "../../assets/images/punch.png";
import Guardian from "../../assets/images/guardian.png";
import Techcabal from "../../assets/images/techcabal.png";
import Techpoint from "../../assets/images/techpoint.png";
import Overlay from "../../assets/images/steam-overlay.webp";

const HeroSection = () => {
    return (
        <Wrapper>
            <NewNavbar dark/>

            <img className={"bg hidden lg:block"} src={Overlay} alt=""/>

            <Container>
                <Left>
                    <div>
                        <h1 className={"text-center lg:text-left"}>
                            Buy and Sell <span>Gift <br/> Cards</span> Instantly In Nigeria</h1>
                        <p className={"desc"}>
                            Download NOSH easy-to-use <br/> application available on mobile and web.
                        </p>
                    </div>


                    <div className={"mt-16 lg:mt-16 relative"}>
                        <Links links/>
                    </div>

                    <div className={"mt-10 lg:mt-20"}>
                        <p className={"text-primary lg:text-left text-center font-bold uppercase"}>As Seen On</p>
                        <div className={"flex items-center flex-wrap justify-center lg:justify-start gap-6 mt-4"}>
                            <img className={"w-[65px] lg:w-[86px]"} src={Punch} alt=""/>
                            <img className={"w-[65px] lg:w-[150px]"} src={Guardian} alt=""/>
                            <img className={"w-[65px] lg:w-[130px]"} src={Techcabal} alt=""/>
                            <img className={"w-[65px] lg:w-[107px]"} src={Techpoint} alt=""/>
                            <img className={"w-[65px] lg:w-[145px]"} src={NM} alt=""/>
                        </div>

                    </div>

                </Left>

                <Right>
                    <img className={"hidden lg:block"} src={Phone} alt=""/>
                    <img className={"lg:hidden w-full"} src={MobilePhone} alt=""/>
                </Right>
            </Container>
        </Wrapper>
    );
};

export default HeroSection;