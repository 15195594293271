import Hero from "../components/NewHero/index";
import Prefer from "../components/Prefer/index";
import Testimonial from "../components/Testimonial";
import DownloadNoshCard from "../components/DownloadNoshCard";
import Footer from "../components/Footer/Footer";
import FAQ from "../components/FAQ";
import YouPlusNosh from "../components/YouPlusNosh";

const homepageData = [
    {
        question: "How Do I Get Started With NOSH?",
        answer: <p>
            Getting started with NOSH is easy! Simply <a rel={"noreferrer"} href="https://onelink.to/dcurna" target="_blank">download the NOSH app</a> from your app store or <a rel={"noreferrer"} href="https://web.usenosh.com/signup" target="_blank">create an account on our website</a>. Once your account is set up, you can begin using our platform. If you need any assistance, our friendly customer support team is available to help.
        </p>
    },
    {
        question: "How Can I Contact NOSH Support?",
        answer: <p>You can contact NOSH support using the live chat, via email <a
            href="mailto:help@nosh.ng">help@nosh.ng</a> or via our support phone number <a href="tel:+234 8125349466">+234 8125349466</a>. We also offer live chat support.</p>
    },
    {
        question: "What Payment Options Does NOSH Support?",
        answer: <p>NOSH supports a range of payment options including bank transfers and mobile money transfers in Nigeria and Ghana.</p>
    },
    {
        question: "How Secure Are My Transactions On NOSH?",
        answer: <p>We take the security of our {"customers'"} transactions very seriously. We use industry-standard encryption technology to ensure that all transactions are safe and secure.</p>
    },
    {
        question: "How Can I Exchange Gift Cards For Cash With NOSH?",
        answer: <p>You can exchange gift cards for cash with NOSH by visiting our website or using the NOSH app to select the gift card you want to sell, enter the gift card details, and receive payment. We offer the <a href="https://usenosh.com/rates">best rates for gift cards</a> and we have an updated list of the <a href="https://usenosh.com/blog/top-10-best-gift-cards-to-sell-in-nigeria">best gift cards with highest rates</a>.</p>
    },
];

const Home = () => {
    return (
        <div>
            <Hero />
            <YouPlusNosh
                text={"Join the NOSH fun squad! We offer easy and quick transactions for everyone. With modern, seamless payment options, we're the ultimate solution for managing finances, gift cards, and mobile payments in Nigeria. Say hello to easy and fun solutions with NOSH!"}
                title={"You + NOSH = A Great Life"}
                buttonText={"Join Nosh Today"}
            />
            <FAQ title={"FAQs"} data={homepageData} />
            <Prefer />
            <Testimonial />
            <DownloadNoshCard />
            <Footer />
        </div>
    );
};

export default Home;