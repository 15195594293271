import {Fade} from "react-reveal";
import {Wrapper, Container, ChildContainer, LeftBox, AppContainer, RightBox} from "./styles";
import AppStore from "../../assets/images/new-apple.svg";
import PlayStore from "../../assets/images/new-play.svg";
import NewApp from "../../assets/images/new-app.webp";
import NewMobileApp from "../../assets/images/new-mobile-app.webp";


const DownloadNoshCard = () => {
    return (
        <Wrapper>
            <Fade bottom>
                <Container>
                    <ChildContainer>
                        <LeftBox>
                            <h3>Download <span>NOSH App</span></h3>
                            <p className={"description"}>Get the APP today to start enjoying amazing deals on gift cards, payments, and bills with #TopNOSHExperience.
                            </p>

                            <div className="download-line"/>

                            <AppContainer>
                                <a
                                    rel="noreferrer"
                                    target="_blank"
                                    href="https://apple.co/3UVMaj4"
                                >
                                    <img loading={"lazy"} src={AppStore} alt=""/>
                                    <div>
                                        <p>Download on the </p>
                                        <p>App Store</p>
                                    </div>
                                </a>

                                <a
                                    rel="noreferrer"
                                    target="_blank"
                                    href="https://bit.ly/3SHHxqM"
                                >
                                    <img loading={"lazy"} src={PlayStore} alt=""/>
                                    <div>
                                        <p>Get it on</p>
                                        <p>Google Play</p>
                                    </div>
                                </a>
                            </AppContainer>
                        </LeftBox>

                        <RightBox>
                            <img className={"desktop-img"} style={{maxWidth: "100%", height: "auto"}} width={423} height={459} src={NewApp} alt="" />
                            <img className={"mobile-img"} style={{maxWidth: "100%", height: "auto"}} width={428} height={381} src={NewMobileApp} alt="" />
                        </RightBox>

                    </ChildContainer>
                </Container>
            </Fade>
        </Wrapper>
    );
};

export default DownloadNoshCard;